import React, {useContext, useEffect, useState} from 'react'
import {PlayerLive, PlayerLoginWindow, PlayerVoucherWindow} from "src/components";
import {AppContext, VideoContext} from "src/layouts";
import {Request} from 'eyecons-requests'

type PlayerVoucherProps = {
    autoplay?: boolean,
    paid: boolean
    setPaid: (paid: boolean) => void
}


export function PlayerVoucher({autoplay = false, paid, setPaid}: PlayerVoucherProps) {
    const [loading, setLoading] = useState(true)
    const {authenticated, authenticatedIsLoading} = useContext(AppContext)
    const {video} = useContext(VideoContext)

    useEffect(() => {
        if (authenticatedIsLoading) {
            return
        }

        if (authenticated) {
            Request.fromApi('ecommerce')
                .get(`/customers/${authenticated.id}/paid/${video.route}/${video.id}`)
                .then(response => {
                    setLoading(false)
                    if (response.data) {
                        setPaid(response.data.owns)
                    }
                })
        } else {
            setLoading(false)
        }
    }, [authenticated, authenticatedIsLoading])

    function renderVoucherWindow() {
        if (paid || video.status !== 'published') {
            return null
        }

        if (authenticated) {
            return (
                <PlayerVoucherWindow setPaid={setPaid}/>
            )
        }

        return <PlayerLoginWindow/>
    }

    if (loading) {
        return null;
    }

    return (
        <>
            {renderVoucherWindow()}

            <PlayerLive
                autoplay={autoplay}
                playable={video.status === 'published' && paid}
                isAuthenticated={Boolean(authenticated)}
            />
        </>
    )
}