import React, {useEffect, useState} from 'react'
import {AppContext} from 'src/layouts'
import {Footer, Nav, Search} from 'src/components'
import * as Requests from "eyecons-requests";
import {useRouter} from "next/router";
import classNames from "classnames";
import {Betting} from 'src/components'

type AppProps = {
    children: React.ReactNode
    options?: {
        waitForAuthenticated?: boolean,
        redirectIfNotAuthenticated?: string
        redirectIfAuthenticated?: string
    },
    hideLayout?: boolean
    hideFooter?: boolean
    hideBetting?: boolean
}

export function App({children, options, hideLayout = false, hideFooter = false, hideBetting = false}: AppProps) {
    const [searching, setSearching] = useState<boolean>(false)
    const waitForAuthenticated = options?.waitForAuthenticated || options?.redirectIfAuthenticated || options?.redirectIfNotAuthenticated

    const router = useRouter()
    const liveCount = Requests.useEventsCount({
        published: true,
        with_live_videos: true,
    }, true)

    const authenticated = Requests.useAuthenticated()

    useEffect(() => {
        if (authenticated.isLoading) {
            return
        }

        if (options?.redirectIfNotAuthenticated && !authenticated.data) {
            router.push(options.redirectIfNotAuthenticated)
        }

        if (options?.redirectIfAuthenticated && authenticated.data) {
            router.push(options.redirectIfAuthenticated)
        }
    }, [authenticated.data, authenticated.isLoading]);

    useEffect(() => {
        if (!authenticated.isLoading) {
            authenticated.mutate()
        }
    }, [router.pathname]);

    if (waitForAuthenticated && authenticated.isLoading) {
        return null
    }

    if (options?.redirectIfNotAuthenticated && !authenticated.data) {
        return null
    }

    if (options?.redirectIfAuthenticated && authenticated.data) {
        return null
    }

    return (
        <AppContext.Provider
            value={{
                authenticated: authenticated.data,
                authenticatedIsLoading: authenticated.isLoading,
                liveCount: liveCount.data.count,
                mutateAuthenticated: authenticated.mutate,
                searching,
                setSearching,
            }}
        >
            {
                !hideLayout && <>
                    <Search/>
                    <Nav/>
                </>
            }
            <main className={classNames('bg-white', {
                'hidden': searching,
                'pt-[60px]': !hideLayout
            })}>{children}</main>
            {!hideLayout && !hideFooter && <Footer/>}
            {!hideBetting && !authenticated.isLoading &&  <Betting showAfterSeconds={5}/>}
        </AppContext.Provider>
    )
}
