import React, {useContext, useState} from 'react'
import * as Requests from 'eyecons-requests'
import {useRouter} from "next/router";
import {PlayerLive, PlayerNoLoginWindow, PlayerVod} from "src/components";
import {VideoContext} from "src/layouts";

type PlayerNoLoginProps = {
    autoplay?: boolean,
    playable: boolean,
    is_live?: boolean
}

export function PlayerNoLogin({is_live, playable, autoplay = false}: PlayerNoLoginProps) {
    const [user, setUser] = useState(null)
    const router = useRouter()
    const {video} = useContext(VideoContext)

    const userExists = Requests.useUserExists(router.query.email as string)

    const isPlayable = (user || userExists.data?.exists) && playable
    const showLogin = !isPlayable && router.query.email && !userExists.isLoading

    return <>
        {showLogin && playable && <PlayerNoLoginWindow/>}

        {is_live ? <PlayerLive
            autoplay={autoplay}
            playable={isPlayable}
            isAuthenticated={isPlayable}
            hideLogin
        /> : <PlayerVod
            video={video}
            autoplay={autoplay}
            playable={isPlayable}
        />}
    </>
}