import {Components} from "eyecons-components";
import {useContext} from "react";
import {AppContext} from "src/layouts";
import {Channel, useChannelsUser, Request} from "eyecons-requests";

type ChannelFollowProps = {
    channel: Channel
}

export function ChannelFollow({channel}: ChannelFollowProps) {
    const {authenticated, authenticatedIsLoading} = useContext(AppContext)

    if (authenticatedIsLoading) {
        return null
    }

    if (!authenticated) {
        return (
            <Components.Buttons.Link
                icon={"notification"}
                to={`/login/?return=/kanalen/${channel.name}`}
                responsive={false}
                type={"primary"}
            >
                Volgen
            </Components.Buttons.Link>
        );
    }

    const channelsUser = useChannelsUser({
        channel_id: channel.id,
        user_id: authenticated.id,
    })

    const is_subscribed = Boolean(channelsUser.data.length);

    function onSubmit() {
        if (is_subscribed) {
            Request.fromApi('backend').delete(`/user_channels/${channelsUser.data[0].id}`).then(response => {
                if (response.data) {
                    channelsUser.mutate()
                }
            })

            return
        }

        Request.fromApi('backend').post('/user_channels', {channel_id: channel.id, user_id: authenticated?.id}).then(response => {
            if (response.data) {
                channelsUser.mutate()
            }
        })
    }

    if (channelsUser.isLoading) {
        return null;
    }

    return (
        <Components.Buttons.Button
            onClick={onSubmit}
            type={"primary"}
            icon={"notification"}
            responsive={false}
        >
            {is_subscribed ? "Ontvolgen" : "Volgen"}
        </Components.Buttons.Button>
    );
}
