import {Channel, useTeams} from "eyecons-requests";
import Link from "next/link";

type ChannelLogosProps = {
    channel: Channel
}

export function ChannelLogos({channel}: ChannelLogosProps) {
    const teams = useTeams({
        competition_id: channel.id,
        hidden_channel: 0,
        sort: "channels.name asc",
    });

    if (teams.isLoading) {
        return null
    }

    return (
        <div className={'grid grid-cols-8 sm:grid-cols-10 md:grid-cols-12 lg:grid-cols-18 gap-2 md:gap-4 justify-center'}>
            {
                teams.data
                    .filter((team) => team.club.icon)
                    .map((team, index) => (
                        <Link
                            key={index}
                            title={team.club.label}
                            href={`/kanalen/${team.club.name}`}
                        >
                            <img className={'rounded-full object-cover'} src={team.club.icon} alt={team.club.label}/>
                        </Link>
                    ))}
        </div>
    );
}
