import type {AppProps} from 'next/app'
import {NextComponentType, NextPageContext} from "next";
import 'src/styles/tailwind.css'
import 'eyecons-components/dist/index.css'
import Head from "next/head";
import React from "react";
import Script from "next/script";

type CustomComponentType = NextComponentType<NextPageContext, any, any> & {
    getLayout?(page: React.ReactNode): React.ReactNode
}

export default function MyApp({Component, pageProps}: AppProps) {
    // Use the layout defined at the page level, if available
    const getLayout = (Component as CustomComponentType).getLayout || ((page) => page)

    return (
        <>
            <Head>
                <script src={`https://cdn.jwplayer.com/libraries/${process.env.NEXT_PUBLIC_JW_PLAYER_ID}.js`}/>
                <title>EYECONS: Volg jouw favoriete sport bij Eyecons, voor de sport, voor de fans</title>
                <meta name={'description'}
                      content={'Op Eyecons.com vind je Live en On Demand video van topclubs in onder andere Voetbal, Hockey, Korfbal, Honkbal, Volleybal, Handbal en Rugby.'}/>
                <meta name="robots" content={process.env.NEXT_PUBLIC_SITE_ENV === 'prod' ? 'index' : 'noindex'}/>
                <meta name="viewport"
                      content="width=device-width, initial-scale=1 initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
                <link rel="icon" type="image/png" sizes="64x64"
                      href={`${process.env.NEXT_PUBLIC_CDN_URL}/front-end/assets/head/favicon-64x64.png`}/>
                <link rel="icon" type="image/png" sizes="32x32"
                      href={`${process.env.NEXT_PUBLIC_CDN_URL}/front-end/assets/head/favicon-32x32.png`}/>
                <link rel="icon" type="image/png" sizes="16x16"
                      href={`${process.env.NEXT_PUBLIC_CDN_URL}/front-end/assets/head/favicon-16x16.png`}/>
                <link rel="apple-touch-icon" sizes="180x180"
                      href={`${process.env.NEXT_PUBLIC_CDN_URL}/front-end/assets/head/apple-touch-icon.png`}/>
                <link rel="manifest" href="/manifest.json"/>
                <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent"/>
                <meta name="apple-mobile-web-app-capable" content="yes"/>
                <link rel="mask-icon"
                      href={`${process.env.NEXT_PUBLIC_CDN_URL}/front-end/assets/head/safari-pinned-tab.svg`}/>
                <link rel="shortcut icon"
                      href={`${process.env.NEXT_PUBLIC_CDN_URL}/front-end/assets/head/favicon.ico`}/>
                <meta name="msapplication-TileColor" content="#14a2d2"/>
                <meta name="msapplication-config"
                      ref={`${process.env.NEXT_PUBLIC_CDN_URL}/front-end/assets/head/browserconfig.xml`}/>
                <meta name="theme-color" content="#ffffff"/>

                <link rel={'canonical'} href={process.env.NEXT_PUBLIC_SITE_URL}/>
            </Head>

            {getLayout(<Component {...pageProps} />)}

            <Script src="https://mmcdn.nl/tags/eyecons.com/home.js" async/>
        </>
    )
}