import {PlayerLive, PlayerNoLogin, PlayerPayment, PlayerVod, PlayerVoucher} from "src/components";
import React, {useContext} from "react";
import {AppContext, VideoContext} from "src/layouts";
import classNames from "classnames";
import {useRouter} from "next/router";

type VideoPlayerProps = {
    paid: boolean,
    setPaid: (paid: boolean) => void,
    is_live?: boolean,
    variant?: 'responsive' | 'fixed' | 'embed'
}

export function VideoPlayer({is_live, variant = 'responsive', paid, setPaid}: VideoPlayerProps) {
    const router = useRouter()
    const {authenticated} = useContext(AppContext)
    const {video} = useContext(VideoContext)
    const hasPayment = video.price && video.price.enabled ? true : video.has_public_plans

    function renderPlayer() {
        const isPlayable = video.status === 'published'

        if (router.query.type === 'nologin') {
            console.log('nologin')
            return (
                <PlayerNoLogin
                    autoplay={true}
                    playable={isPlayable}
                    is_live={is_live}
                />
            )
        }
        if (hasPayment && router.query.voucher) {
            return  (
                <PlayerVoucher
                    autoplay={true}
                    paid={paid}
                    setPaid={setPaid}
                />
            )
        }

        if (hasPayment) {
            return (
                <PlayerPayment
                    autoplay={true}
                    paid={paid}
                    setPaid={setPaid}
                    is_live={is_live}
                />
            )
        }

        if (is_live) {
            return (
                <PlayerLive
                    autoplay={true}
                    playable={Boolean(isPlayable && authenticated?.id)}
                    isAuthenticated={Boolean(authenticated)}
                />
            )
        }

        return (
            <PlayerVod video={video} autoplay={true} playable={isPlayable}/>
        )
    }

    return (
        <div className={classNames('z-40 mx-auto', {
            'fixed left-0 right-0 top-[60px] md:static max-w-[1000px]': variant === 'responsive',
            'w-full h-full': variant === 'embed'
        })}>
            <div className={classNames('relative', {
                'w-full h-full': variant === 'embed'
            })}>
                {renderPlayer()}
            </div>
        </div>
    )
}