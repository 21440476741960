import React, {useContext} from 'react'
import {AppContext} from "src/layouts";
import {PlayerShare} from "src/components";
import {Components} from "eyecons-components";
import * as Requests from 'eyecons-requests'

type PlayerVodProps = {
    video: Requests.Video
    autoplay?: boolean
    playable?: boolean
    polling?: boolean
}

export function PlayerVod({video, autoplay = false, playable = false, polling = false}: PlayerVodProps) {
    const {authenticated} = useContext(AppContext)

    if (!playable) {
        return (
            <img src={video.thumbnail_large} className={'object-cover aspect-video'} alt=""/>
        )
    }

    return <div className={'relative'}>
        <Components.Players.Player
            video={video}
            polling={polling}
            autoplay={autoplay}
            playable={playable}
            user={authenticated}
        />

        <PlayerShare video={video}/>
    </div>
}